import React from "react"
import styled from "styled-components"
import SEO from "../components/seo"
import { navigate } from "gatsby"
import logo from "../assets/images/Toot_Logo.svg"
import illustration from "../assets/animations/404/toot-web-404.svg"
import { HeaderLargeText, LgContentText } from "../elements/typography"
import { PrimaryBtn } from "../elements/buttons"
import { GlobalStyles } from "../utilities/global"
import { StyledFooter } from "../components/footer"

const Styled404Wrapper = styled.div`
  text-align: center;
  .logo {
    margin-top: 20px;
    @media (min-width: 900px) {
      margin-top: 130px;
    }
  }
  .illustration {
    max-width: 700px;
    width: 100%;
  }
  h4 {
    max-width: 680px;
    margin: 24px auto;
    padding: 0 15px;
  }
  button {
    margin: auto;
  }
`
const home = () => {
  navigate("/")
}

const NotFoundPage = () => (
  <Styled404Wrapper>
    <GlobalStyles />
    <SEO title="404: Not found" />
    <img src={logo} alt="logo" className="logo" />
    <HeaderLargeText>Oops! I think we’re lost</HeaderLargeText>
    <LgContentText>
      It looks like you were travelling the web at 184mph. Thats way above any
      speed limit. While we work on a solution, please go back to the home page.
    </LgContentText>
    <PrimaryBtn onClick={() => home()}>Back to Home</PrimaryBtn>
    <img src={illustration} alt="illustration" className="illustration" />
    <StyledFooter />
  </Styled404Wrapper>
)

export default NotFoundPage
